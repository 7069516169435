<template>
      <div class="box" style="margin:auto;padding:0px" height="95%" width="95%">
        <iframe
          :src="url_api_docs"
          frameborder="0" style="overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px;margin:auto" height="100%" width="100%"
        ></iframe>
      </div>
</template>
<script>
export default {
  name: "SwaggerView",
  components: {},
  data: () => ({
    swagger_view: "hola",
  }),
  computed: {
    url_api_docs: function () {
      return process.env.VUE_APP_BASE_URI + "/admin/docs";
    },
  },
};
</script>