<template>
  <body>
    <body class="container has-navbar-fixed-bottom">
      <div class="container">
        <div class="columns is-mobile is-vcentered">
          <div class="column is-2">
            <b-switch v-model="order_is_book" @input="set_order_type" >Pedido de programacion</b-switch>
          </div>
          <div class="column is-8" v-if="!order_is_book">
            <b-field>
              <b-switch v-model="positive_stock" @input="new_search">Mostrar articulos en stock</b-switch>
              <b-button v-if="catalog_download_in_process" type="is-primary is-light" expanded>
                <div class="level">
                  <div class="level-item">
                    Descargando stocks
                  </div>
                  <div class="level-item">
                    <b-progress type="is-primary"></b-progress>
                  </div>
                </div>
              </b-button>
              <b-button v-else expanded type="is-primary" @click="download_catalog">Descargar Informe de Articulos</b-button>
            </b-field>
          </div>
          <div class="column is-8" v-if="order_is_book">
            <b-field>
              <b-datepicker
                v-model="booking_date"
                :show-week-number="true"
                :date-formatter="formatter"
                :locale="locale"
                placeholder="Selecciona tu fecha de entrega"
                :unselectable-days-of-week="[0, 6]"
                position="is-bottom-right"
                expanded
                @input="set_booking_date"
              />
            </b-field>
          </div>
          <div class="column is-2">
            <b-pagination
              v-model            ="clientPage"
              order              =""
              size               ="is-small"
              icon-prev          ="chevron-left"
              icon-next          ="chevron-right"
              :simple            = true
              :current.sync      ="clientPage"
              :total             ="total_items"
              :per-page          ="perPage"
              :range-before      ="0"
              :range-after       ="1"
              v-on:change        ="changePage"
            ></b-pagination>
          </div>
        </div>
        <div v-if="products.length===0" class="container">
          <div class="columns is-centered">
            <div class="column">
              <p class="image is-64x64">
                <img
                  :src="get_default_image_url()"
                  style="height: 100"
                />
              </p>
              <p>
                No hemos encontrado ninguna coincidencia para tu busqueda.
              </p>
            </div>
          </div> 
        </div>
        <div class="columns is-multiline is-variable is-mobile">
          <div class="column is-12-mobile is-4-tablet is-3-desktop is-3-widescreen is-2-fullhd" v-for="product in products" :key=product.vue_key >
          <catalogEntryPack v-if="product.item_type=='packs_group' || product.item_type=='pack'"
            :product_info="product"
            :display_prices="display"
          />
          <catalogEntry v-else
            :product_info="product"
            :display_prices="display"
          />
          </div>
        </div>
        <div class="columns is-mobile is-vcentered">
          <div class="column is-2">
            <b-switch v-model="order_is_book" @input="set_order_type" >Pedido de programacion</b-switch>
          </div>
          <div class="column is-8" v-if="!order_is_book">
            <b-field>
              <b-switch v-model="positive_stock" @input="new_search">Mostrar articulos en stock</b-switch>
              <b-button v-if="catalog_download_in_process" type="is-primary is-light" expanded>
                <div class="level">
                  <div class="level-item">
                    Descargando stocks
                  </div>
                  <div class="level-item">
                    <b-progress type="is-primary"></b-progress>
                  </div>
                </div>
              </b-button>
              <b-button v-else expanded type="is-primary" @click="download_catalog">Descargar Informe de Articulos</b-button>
            </b-field>
          </div>
          <div class="column is-8" v-if="order_is_book">
            <b-field>
              <b-datepicker
                v-model="booking_date"
                :show-week-number="true"
                :date-formatter="formatter"
                :locale="locale"
                placeholder="Selecciona tu fecha de entrega"
                :unselectable-days-of-week="[0, 6]"
                position="is-bottom-right"
                expanded
                @input="set_booking_date"
              />
            </b-field>
          </div>
          <div class="column is-2">
            <b-pagination
              v-model            ="clientPage"
              order              =""
              size               ="is-small"
              icon-prev          ="chevron-left"
              icon-next          ="chevron-right"
              :simple            = true
              :current.sync      ="clientPage"
              :total             ="total_items"
              :per-page          ="perPage"
              :range-before      ="0"
              :range-after       ="1"
              v-on:change        ="changePage"
            ></b-pagination>
          </div>
        </div>
      </div>
      
    </body>

    <b-navbar :fixed-bottom="true" :close-on-click="false" :centered="true">
      <template slot="start">
        <b-navbar-item>
          <div class="columns">
            <div class="column">
              <div class="container">
                <div class="columns">
                  <div class="column is-one-eigth">
                    <b-input v-model="search_text" :lazy="true" size="is-small" placeholder="Buscador" v-on:input="new_search"></b-input>
                  </div>
                  <catalog-filter
                    v-for="filter in filters"
                    :key="filter.name"
                    :filter_info="filter"
                    v-on:mySearch="new_search"
                  ></catalog-filter>
                </div>
              </div>
            </div>
          </div>
        </b-navbar-item>

        <b-navbar-item v-if="user_role=='administrator' || user_role=='commercial'">
          <b-field label="Cliente" label-position="on-border">
            <b-select 
              v-model="selected_client"
              size="is-small"
              @input="get_products">
              <option :value="null">
                  -
              </option>
              <option
                  v-for="client in clients"
                  :value="client.id"
                  :key="client.id">
                  {{ client.name }}
              </option>
            </b-select>
          </b-field>
        </b-navbar-item>
      </template>
    </b-navbar>
    <b-loading :is-full-page=true v-model="loading" :can-cancel="true">
      <b-icon
        pack="fas"
        icon="sync-alt"
        size="is-large"
        custom-class="fa-spin">
      </b-icon>
    </b-loading>
  </body>
</template>

<script>
import { mapGetters }    from "vuex";
import { keaaApi }       from "@/plugins/keaa_api";
import catalogFilter     from "@/components/catalog-filter";
import catalogEntry      from "@/components/catalog-entry";
import catalogEntryPack  from "@/components/catalog-entry-pack";

export default {
  name: "Catalog",
  components: {
    catalogFilter,
    catalogEntry,
    catalogEntryPack
  },
  data() {
    return {
      order_is_book    : false,
      booking_date     : null,
      positive_stock   : false,
      total_items      : 500,
      clientPage       : 1,
      perPage          : 50,
      order            : "is-centered",
      size             : "is-small",
      filters          : [],
      search_text      : "",
      loading_filters  : true,
      loading_products : true,
      loading          : true,
      clients          : [],
      selected_client  : null,
      display          : {
        'current_discount' : false,
        'custom_rate'      : true,
        'custom_rpvp'      : false
      }
    };
  },
  computed: {
    ...mapGetters(["seller_id", "catalog_page", "catalog_download_in_process"]),
    user_id: function () {
      return this.$store.state.user_id;
    },
    catalog_owner: function () {
      if (this.selected_client === null) {
        return this.user_id;
      } else {
        return this.selected_client;
      }
    },
    user_role: function () {
      var role = this.$store.state.user_is_admin;
      return role;
    },
    products: function () {
      return this.$store.state.product_catalog; 
    },
    locale: function () {
      return this.$store.state.locale;
    }
  },

  methods: {
    new_search: function () {
      this.clientPage = 1;
      this.get_products();
      window.scrollTo(0,0)
    },
    changePage: function () {
      this.get_products();
      window.scrollTo(0,0)
    },
    get_default_image_url: function () {
      var url;
      url = process.env.VUE_APP_BASE_URI + "/b2b/images/by_file/" + "default";
      return url;
    },
    download_catalog: function (){
      var request_filters = {};
      this.$store.commit("set_catalog_download_in_process", true)

      for (var key of Object.keys(this.filters)) {
        if (this.filters[key]["selected"] != null) {
          request_filters[this.filters[key]["name"]] = this.filters[key]["selected"];
        }
      }

      keaaApi({
        method: "POST",
        url: "b2b/catalog/products",
        data: {
          user_id         : this.catalog_owner,
          filters         : request_filters,
          search_term     : this.search_text,
          only_positive   : this.positive_stock,
          to_file         :'xlsx'
        },
        responseType: 'blob'
      }).then(
        (response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', 'stock.xlsx')
          document.body.appendChild(fileLink)
          fileLink.click()
          fileLink.remove()
          window.URL.revokeObjectURL(fileURL);
          this.$store.commit("set_catalog_download_in_process", false)

          // const blob = new Blob([response.data], {type: response.data.type});
          // const url = window.URL.createObjectURL(blob);
          // const link = document.createElement('a');
          // link.href = url;
          // const contentDisposition = response.headers['content-disposition'];
          // let fileName = 'unknown';
          // if (contentDisposition) {
          //     const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          //     if (fileNameMatch.length === 2)
          //         fileName = fileNameMatch[1];
          // }
          // link.setAttribute('download', fileName);
          // document.body.appendChild(link);
          // link.click();
          // link.remove();
          // window.URL.revokeObjectURL(url);
        },
        (error) => {
          alert(error);
        }
      );
    },
    get_products: function () {
      this.$store.commit("set_catalog_page", this.clientPage);
      this.$store.commit("set_customer", this.selected_client);
      this.loading_products = true;
      this.loading = this.loading_products | this.loading_filters;
      var request_filters = {};
      
      for (var key of Object.keys(this.filters)) {
        if (this.filters[key]["selected"] != null) {
          request_filters[this.filters[key]["name"]] = this.filters[key]["selected"];
        }
      }

      keaaApi({
        method: "POST",
        url: "b2b/catalog/products",
        data: {
          user_id         : this.catalog_owner,
          filters         : request_filters,
          search_term     : this.search_text,
          page            : this.clientPage,
          perPage         : this.perPage,
          only_positive   : this.positive_stock,
          booking_prices  : this.order_is_book
        },
      }).then(
        (result) => {
          var response = result.data;
          this.display = response.display;
          this.$store.commit("set_price_display", this.display);
          this.$store.commit("product_catalog", response.items);

          if (response.items.length < this.total_items){
            this.total_items = this.total_items - this.perPage + response.items.length;
          }
        
          if (response.more_pages){
            this.total_items = this.clientPage * this.perPage + this.perPage;
          }
          this.loading_products = false;
          this.loading = this.loading_products | this.loading_filters;

        },
        (error) => {
          alert(error);
        }
      );
    },
    get_page_from_store: function () {
      return this.catalog_page;
    },
    formatter(d) {
      return d.toLocaleDateString(this.locale);
    },
    set_order_type: function () {
      this.$store.commit("set_order_type", this.order_is_book);
      this.$store.commit("empty_shopping_cart");
      this.new_search();
      window.scrollTo(0,0)
    },
    set_booking_date: function () {
      this.$store.commit("set_booking_date", this.booking_date);
    }
  },


  beforeMount() {
    this.$store.commit("set_catalog_download_in_process", false)
    var role = this.user_role;
    keaaApi({
      method: "GET",
      url   : "b2b/catalog/filters/" + this.catalog_owner,
    }).then((result) => {
      this.filters         = result.data.items;
      this.loading_filters = false
      this.loading         = this.loading_products | this.loading_filters;
    });

    if (role === "administrator") {
      keaaApi({
        method: "GET",
        url: "b2b/clients/all",
      }).then((result) => {
        this.clients = result.data.items;
      });
    } else if (role === "commercial") {
      keaaApi({
        method: "GET",
        url: "b2b/clients/all",
        params: { seller_id: this.seller_id },
      }).then((result) => {
        this.clients = result.data.items;
      });
    }
  },

  mounted() {
    this.clientPage      = this.get_page_from_store();
    this.selected_client = this.$store.state.customer;
    this.display         = this.$store.state.price_display;
    this.order_is_book   = this.$store.state.order_is_book;
    var vuex_date        = this.$store.state.booking_date;
    
    if(typeof vuex_date==='string'){
      this.booking_date = new Date(vuex_date) 
    }else{
      this.booking_date = vuex_date
    }


    if (this.products.length == 0) {
      this.get_products();
    }
    else {
      this.loading_products = false;
      this.loading = this.loading_products | this.loading_filters;
    }
    this.$store.commit("purge_shopping_cart");
  }
};
</script>

<style scoped>
.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.filter {
  flex: 0 0 auto;
}

.center {
  /*display: block;
  max-width: 100%;
  max-height: 100%;
  width: auto;*/
  /*height: auto;*/
  /*border: 5px solid #555;*/
  margin: 30px;
  /*align-self:center;*/
}
</style>