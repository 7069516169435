<template>
<div class="columns">
    <div class="column is-1">
    </div>
    <div class="column is-9" style="text-align:left !important">
      <h1><strong>{{ info.brand }} _ {{ info.cf_nombre_comercial_unformatted }}</strong></h1>
      <ul>
        <li>&emsp;&emsp;<strong>{{ upper(info.attribute_name1) }}</strong>: {{ upper(info.attribute_option_name1) }}</li>
        <li>&emsp;&emsp;<strong>{{ upper(info.attribute_name2) }}</strong>: {{ upper(info.attribute_option_name2) }}</li>
        <li>&emsp;&emsp;<strong>{{ upper(info.attribute_name3) }}</strong>: {{ upper(info.attribute_option_name3) }}</li>
      </ul>
    </div>
    <div class="column is-2">
      <p class="image is-64x64">
        <img :src="get_product_image_url(info)" />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "cartPackDetail",
  props: ["info", "cart_key"],
  data() {
    return {
      item_amount_to_order: 0,
    };
  },
  methods: {
    get_product_image_url: function (item) {
      var url;
      if (item.image_name != "") {
        url = process.env.VUE_APP_BASE_URI + "/b2b/images/by_file/" + item.image_name;
        return url;
      } else {
        url = process.env.VUE_APP_BASE_URI + "/b2b/images/by_file/" + "default";
        return url;
      }
    },
    upper: function (text) {
      if (!(typeof text === "undefined")) {
        return text.toUpperCase();
      } else {
        return undefined;
      }
    }
  },
};
</script>