<template>
  <div class="container">
      <b-table
        :data="orders"
        :columns="columns"
        detailed
        striped
        v-on:mouseenter="(row) => get_order_detail(row)"
      >
        <template slot="detail" slot-scope="props">
          <div class="container">
            <b-table
              :data="props.row.line_items"
              :columns="detail_columns"
              narrowed
            >
            </b-table>
          </div>
        </template>
      </b-table>
    <b-navbar :fixed-bottom="true" :close-on-click="false" :centered="true">
      <template slot="start">
        <b-navbar-item>
          <b-field
            label="Desde"
            label-position="on-border"
            expanded
            style="margin: 5px"
          >
            <b-datepicker
              v-model="startDate"
              :show-week-number="true"
              :date-formatter="formatter"
              :locale="locale"
              placeholder="Click to select..."
              size="is-small"
              expanded
              position="is-top-right"
            />
            <b-button
              icon-left="close-circle"
              size="is-small"
              @click="clearStartDate"
            />
          </b-field>
        </b-navbar-item>
        <b-navbar-item>
          <b-field
            label="Hasta"
            label-position="on-border"
            expanded
            style="margin: 5px"
          >
            <b-datepicker
              v-model="endDate"
              :show-week-number="true"
              :date-formatter="formatter"
              :locale="locale"
              placeholder="Click to select..."
              size="is-small"
              expanded
              position="is-top-right"
            />
            <b-button
              icon-left="close-circle"
              size="is-small"
              @click="clearEndDate"
            />
          </b-field>
        </b-navbar-item>
        <b-navbar-item>
          <b-field
            label="Estado"
            label-position="on-border">
            <b-select
              v-model="state"
              size="is-small">
              <option :value="null">Todos</option>
              <option value="draft">Borrador</option>
              <option value="confirmed">Confirmado</option>
              <option value="fulfilled">Cerrado</option>
            </b-select>
          </b-field>
        </b-navbar-item>
        <b-navbar-item>
          <b-field
            v-if="user_role == 'administrator' || user_role == 'commercial'"
            label="Cliente"
            label-position="on-border">
            <b-select
              size="is-small"
              v-model="selected_client"
              @input="get_orders"
            >
              <option :value="null">-</option>
              <option
                v-for="client in clients"
                :value="client.id"
                :key="client.id"
              >
                {{ client.name }}
              </option>
            </b-select>
          </b-field>
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<script>
import { keaaApi } from "@/plugins/keaa_api";
import { mapGetters } from "vuex";

export default {
  name: "OrdersTable",
  data() {
    return {
      orders: [],
      startDate: null,
      endDate: null,
      state: null,
      selected_client: null,
      clients: [],
      columns: [
        {
          field: "salesorder_number",
          label: "Order number",
          sortable: true,
        },
        {
          field: "date",
          label: "Date",
          sortable: true,
        },
        {
          field: "balance",
          label: "Balance",
          sortable: true,
        },
        {
          field: "order_status",
          label: "Status",
          sortable: true,
        },
        {
          field: "quantity",
          label: "Items ordered",
          sortable: true,
        },
        {
          field: "quantity_invoiced",
          label: "Items invoiced",
        },
        {
          field: "quantity_shipped",
          label: "Items shipped",
        },
      ],
      detail_columns: [
        {
          field: "sku",
          label: "Sku",
          width: "40",
        },
        {
          field: "rate",
          label: "Rate",
          width: "40",
        },
        {
          field: "quantity",
          label: "Items ordered",
          width: "40",
        },
        {
          field: "quantity_invoiced",
          label: "Items invoiced",
          width: "40",
        },
        {
          field: "quantity_shipped",
          label: "Items shipped",
          width: "40",
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["seller_id", "locale"]),
    user_id: function () {
      return this.$store.state.user_id;
    },
    user_role: function () {
      var role = this.$store.state.user_is_admin;
      return role;
    },
    orders_owner: function () {
      if (this.selected_client === null) {
        return this.user_id;
      } else {
        return this.selected_client;
      }
    },
    locale: function () {
      return this.$store.state.locale;
    },
  },

  methods: {
    get_orders: function () {
      this.$store.commit("set_customer", this.selected_client);
      let params= {}
      if (this.startDate) {
        params.start_date = this.startDate.toLocaleDateString(this.locale)
      }
      if (this.endDate) {
        params.end_date = this.endDate.toLocaleDateString(this.locale)
      }
      if (this.state) {
        params.status = this.state
      }

      keaaApi({
        method: "GET",
        url: "b2b/orders/" + this.orders_owner,
        params
      }).then((result) => {
        var response = result.data;
        this.orders = response.items;
      });
    },

    get_order_detail: async function (row) {
      if (!("line_items" in row)) {
        keaaApi({
          method: "GET",
          url: "b2b/orders/detail/" + row.salesorder_id,
        }).then(function (result) {
          row.line_items = result.data.items;
        });
      }
    },

    formatter(d) {
      return d.toLocaleDateString(this.locale);
    },

    clearStartDate() {
      this.startDate = null;
    },

    clearEndDate() {
      this.endDate = null;
    },
  },

  mounted: function () {
    this.selected_client = this.$store.state.customer;
    this.get_orders();

    if (this.user_role === "administrator") {
      keaaApi({
        method: "GET",
        url: "b2b/clients/all",
      }).then((result) => {
        this.clients = result.data.items;
      });
    } else if (this.user_role === "commercial") {
      keaaApi({
        method: "GET",
        url: "b2b/clients/all",
        params: { seller_id: this.seller_id },
      }).then((result) => {
        this.clients = result.data.items;
      });
    }
  },
};
</script>
