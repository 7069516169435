import Vue         from 'vue'
import VueRouter from 'vue-router'

import Catalog                 from '@/components/catalog'
import OrdersTable             from '@/components/orders_table'
import ShoppingCart            from '@/components/shopping_cart'
import SwaggerView             from '@/components/swagger-view'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Hello',
        component: Catalog,
    },
    {
        path: '/shop',
        name: 'Catalog',
        component: Catalog
    },
    {
        path: '/cart',
        name: 'Cart',
        component: ShoppingCart
    },
    {
        path: '/orders',
        name: 'Orders',
        component: OrdersTable
    },
    {
        path: '/admin',
        name: 'SwaggerView',
        component: SwaggerView
    }
]
 
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
