<template>
  <div>
    <section class="modal-card-body">
      <b-field label="Email">
        <b-input
          type="email"
          v-model="email"
          placeholder="Your email"
          required
        ></b-input>
      </b-field>

      <b-field label="Password">
        <b-input
          type="password"
          v-model="password"
          password-reveal
          placeholder="Your password"
          required
        ></b-input>
      </b-field>

      <!--b-checkbox>Remember me</b-checkbox-->
    </section>
    <footer class="modal-card-foot">
      <button class="button is-primary" @click="login_to_keaa">
        Login
      </button>
    </footer>
    <b-loading :is-full-page=true v-model="sending_login" :can-cancel=false>
      <b-icon
        pack="fas"
        icon="sync-alt"
        size="is-large"
        custom-class="fa-spin">
      </b-icon>
    </b-loading>
  </div>
</template>

<script>
import axios       from "axios";
//import { keaaApi } from "@/plugins/keaa_api";

export default {
  name: "ExistingUserForm",
  components: {},
  data() {
    return {
      email: null,
      password: null,
      sending_login:false
    };
  },

  methods: {
    login_to_keaa() {
      this.sending_login = true;
      var usr = this.email;
      var pwd = this.password;

      axios({
        method: "GET",
        url: process.env.VUE_APP_BASE_URI + "/login/login_basic",
        auth: {
          username: usr,
          password: pwd,
        },
        withCredentials: true,
      }).then((result) => {
        var response       = result.data;
        var user_id        = response.user_id;
        var seller_id      = response.sales_id;
        var admin          = response.keaa_admin;
        this.$store.commit("user_id", user_id);
        this.$store.commit("seller_id", seller_id);
        this.$store.commit("user_is_admin", admin);

        this.$emit('login_successful');
      }).catch(() => {
        this.email = null
        this.password = null
        this.sending_login = false;
      });
    },
  },
};
</script>
