<template>
  <div class="container">
    <div class="container" v-if="order_is_book">
      <div class="columns is-mobile is-vcentered">
        <div class="column is-2"></div>
        <div class="column is-8">
          <b-field>
            <b-notification
              expanded
              type="is-primary is-light"
              :closable="false"
              style="text-align: center"
              >Estas tramitando un pedido de programacion para la fecha:
              {{ formatted_booking_date }}</b-notification
            >
          </b-field>
        </div>
        <div class="column is-2"></div>
      </div>
    </div>
    <b-table
      :data="cart_rows"
      :sticky-header="true"
      height="500"
      detailed
      narrowed
      striped
    >
      <template slot="detail" slot-scope="props">
        <cartItemDetail
          v-if="!props.row.item_dict.is_combo_product"
          :info="props.row.item_dict"
          :cart_key="props.row.key"
        />
        <cartPackDetail
          v-else
          :info="props.row.item_dict"
          :cart_key="props.row.key"
        />
      </template>
      <b-table-column label="Marca" width="40" sortable v-slot="cart_lines">
        {{ cart_lines.row.brand }}
      </b-table-column>
      <b-table-column label="Nombre" width="40" sortable v-slot="cart_lines">
        {{ cart_lines.row.name }}
      </b-table-column>
      <b-table-column label="SKU" width="15" v-slot="cart_lines">
        {{ cart_lines.row.sku }}
      </b-table-column>
      <b-table-column label="EAN" width="15" v-slot="cart_lines">
        {{ cart_lines.row.ean }}
      </b-table-column>
      <b-table-column label="Precio" width="50" sortable v-slot="cart_lines">
        {{ cart_lines.row.price }} EUR.
      </b-table-column>
      <b-table-column
        label="Descuento [%]"
        width="10"
        sortable
        v-slot="cart_lines"
      >
        <b-input
          :placeholder="discount"
          type="number"
          min="0"
          max="50"
          v-model="cart_lines.row.discount"
          size="is-small"
          style="text-align: center !important"
          @input="modify_row(cart_lines.row)"
        />
      </b-table-column>
      <b-table-column label="Unidades" width="10" sortable v-slot="cart_lines">
        <b-input
          placeholder="0"
          type="number"
          min="0"
          v-model="cart_lines.row.amount"
          size="is-small"
          style="text-align: center !important"
          @input="modify_row(cart_lines.row)"
        />
      </b-table-column>
    </b-table>
    <b-navbar :fixed-bottom="true" :close-on-click="false" :centered="true">
      <template slot="start">
        <div class="columns is-centered">
          <div class="column is-1">
            <b-field>
              <strong>Resumen:</strong>
            </b-field>
          </div>
          <div class="column is-1">
            <b-field label="Articulos" label-position="on-border">
              <b-input
                size="is-small"
                :value="items_total()"
                disabled
              ></b-input>
            </b-field>
          </div>
          <div class="column is-1">
            <b-field label="SubTotal" label-position="on-border">
              <b-input
                size="is-small"
                :value="rate_subtotal()"
                disabled
              ></b-input>
            </b-field>
          </div>
          <div class="column is-1">
            <b-field label="Descuento" label-position="on-border">
              <b-input size="is-small" v-model="discount"></b-input>
            </b-field>
          </div>
          <div class="column is-1">
            <b-field label="Total" label-position="on-border">
              <b-input size="is-small" :value="rate_total()" disabled></b-input>
            </b-field>
          </div>
          <div
            class="column is-2"
            v-if="user_role == 'administrator' || user_role == 'commercial'"
          >
            <b-field label="Cliente" label-position="on-border">
              <b-select
                v-model="selected_client"
                size="is-small"
                @input="set_customer"
              >
                <option :value="null">-</option>
                <option
                  v-for="client in clients"
                  :value="client.id"
                  :key="client.id"
                >
                  {{ client.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-1">
            <b-field>
              <b-button
                type="is-primary"
                size="is-small"
                v-on:click="post_order"
                >Enviar Pedido</b-button
              >
            </b-field>
          </div>
        </div>
      </template>
    </b-navbar>
    <b-loading :is-full-page="true" v-model="sending_order" :can-cancel="false">
      <b-icon pack="fas" icon="sync-alt" size="is-large" custom-class="fa-spin">
      </b-icon>
    </b-loading>
  </div>
</template>


<script>
import { keaaApi }    from "@/plugins/keaa_api";
import { mapGetters } from "vuex";

import cartItemDetail from "@/components/cart-item-detail";
import cartPackDetail from "@/components/cart-pack-detail";

export default {
  name: "ShoppingCart",
  components: { cartItemDetail, cartPackDetail },
  data() {
    return {
      sending_order: false,
      clients: [],
      selected_client: null,
      discount: 0,
    };
  },

  computed: {
    ...mapGetters(["seller_id", "order_is_book", "booking_date", "locale"]),
    user_id: function () {
      return this.$store.state.user_id;
    },
    user_role: function () {
      var role = this.$store.state.user_is_admin;
      return role;
    },
    cart_rows: function () {
      return this.get_cart();
    },
    formatted_booking_date() {
      return this.booking_date.toLocaleDateString();
    },
  },

  methods: {
    set_customer: function () {
      this.$store.commit("set_customer", this.selected_client);
    },
    get_cart: function () {
      const cart = this.$store.state.shopping_cart;
      let rows = [];
      for (let key in cart) {
        var item_dict = cart[key];
        if (item_dict.item.is_combo_product) {
          rows.push({
            amount: item_dict.amount,
            ean: item_dict.item.ean,
            sku: item_dict.item.sku,
            name: item_dict.item.cf_nombre_comercial_unformatted,
            brand: item_dict.item.brand,
            kind: item_dict.item.cf_grupo_unformatted,
            key: key,
            item_dict: item_dict.item,
            discount: item_dict.discount,
            price: item_dict.price,
          });
        } else {
          rows.push({
            amount: item_dict.amount,
            ean: item_dict.item.ean,
            sku: item_dict.item.sku,
            name: item_dict.item.cf_nombre_comercial_unformatted,
            brand: item_dict.item.brand,
            kind: item_dict.item.cf_grupo_unformatted,
            key: key,
            item_dict: item_dict.item,
            discount: item_dict.discount,
            price: item_dict.price,
          });
        }
      }
      return rows;
    },
    modify_row: function (row) {
      if (row.item_dict.is_combo_product) {
        this.$store.commit("add_pack_to_shopping_cart", {
          product : row.item_dict,
          amount  : parseInt(row.amount),
          discount: row.discount,
          price   : row.price,
        });
      } else {
        this.$store.commit("add_item_to_shopping_cart", {
          product : row.item_dict,
          amount  : parseInt(row.amount),
          discount: row.discount,
          price   : row.price,
        });
      }
    },
    items_total: function () {
      var total = 0;
      for (const item_dict of this.cart_rows) {
        total += Number(item_dict.amount);
      }
      return total + " articulos.";
    },
    rate_total: function () {
      var total = 0;
      for (const item_dict of this.cart_rows) {
        let discount = null;
        if (item_dict.discount) {
          discount = item_dict.discount;
        } else {
          discount = this.discount;
        }  
        let item_rate = parseFloat(item_dict.price);
        item_rate = item_rate - (item_rate * discount) / 100;
        total += item_rate * Number(item_dict.amount);
      }
      return total.toFixed(2) + " EUR";
    },
    rate_subtotal: function () {
      var total = 0;
      for (const item_dict of this.cart_rows) {
        total +=
          parseFloat(item_dict.price) *
          Number(item_dict.amount);
      }
      return total.toFixed(2) + " EUR";
    },
    post_order: function () {
      this.sending_order = true;
      var cart = this.$store.state.shopping_cart;
      var rows = [];
      var target_contact = this.user_id;
      if (this.selected_client != null) {
        target_contact = this.selected_client;
      }
      for (let key in cart) {
        var item_dict = cart[key];
        let discount = null;
        if (item_dict.discount) {
          discount = item_dict.discount;
        } else {
          discount = this.discount;
        }

        if ("item_id" in item_dict.item) {
          rows.push({
            discount: discount,
            price   : item_dict.price,
            amount  : item_dict.amount,
            item_id : item_dict.item.item_id,
          });
        } else {
          rows.push({
            discount: discount,
            price   : item_dict.price,
            amount  : item_dict.amount,
            item_id : item_dict.item.composite_item_id,
          });
        }
      }
      let request_data = {}
      if (this.order_is_book) {
        request_data.client_id    = target_contact,
        request_data.seller_id    = this.seller_id,
        request_data.order_items  = rows,
        request_data.delivery_date= this.formatted_booking_date
      } else {
        request_data.client_id  = target_contact,
        request_data.seller_id  = this.seller_id,
        request_data.order_items= rows
      }
      keaaApi({
        method: "POST",
        url: "b2b/orders/new",
        data: request_data
      }).then(() => {
        this.$store.commit("empty_shopping_cart");
        this.sending_order = false;
        this.$router.push("/orders");
      });
    },
  },
  mounted: function () {
    this.selected_client = this.$store.state.customer;
    // this.get_cart();
    if (this.user_role === "administrator") {
      keaaApi({
        method: "GET",
        url: "b2b/clients/all",
      }).then((result) => {
        this.clients = result.data.items;
      });
    } else if (this.user_role === "commercial") {
      keaaApi({
        method: "GET",
        url: "b2b/clients/all",
        params: { seller_id: this.seller_id },
      }).then((result) => {
        this.clients = result.data.items;
      });
    }
  },
};
</script>

