<template>
  <div>
    <section class="modal-card-body">
      <b-steps
        v-model="activeStep"
        :animated=true
        :rounded=true
        :has-navigation=true
        icon-prev="chevron-left"
        icon-next="chevron-right"
        mobile-mode="minimalist"
      >
        <b-step-item 
          step="1" 
          label="Personal Details" 
          :clickable="true">
          <b-field label="Empresa">
            <b-input  v-model="s1_company" required></b-input>
          </b-field>
          <b-field grouped>
            <b-field label="Personal title">
              <b-select v-model="s1_treatment">
                <option>Sr.</option>
                <option>Sra.</option>
              </b-select>
            </b-field>
            <b-field label="Nombre" expanded>
              <b-input v-model="s1_contactName" required></b-input>
            </b-field>
            <b-field label="Apellidos" expanded>
              <b-input v-model="s1_contactSurname" required></b-input>
            </b-field>
          </b-field>
          <b-field label="Correo Electronico">
            <b-input v-model="s1_contactEmail" required type="email"></b-input>
          </b-field>
          <b-field grouped>
            <b-field label="Telefono" expanded>
              <b-input v-model="s1_contactPhone"    pattern="((\+34) (9)[0-9]{2} [0-9]{3} [0-9]{3}\b)|((\+376) (3|7|8)[0-9]{2} [0-9]{3}\b)|((\+351) (2)[0-9]{2} [0-9]{3} [0-9]{3}\b)"></b-input>
            </b-field>
            <b-field label="Movil" expanded >
              <b-input v-model="s1_contactCellular" pattern="((\+34) (6|7)[0-9]{2} [0-9]{3} [0-9]{3}\b)|((\+376) (6)[0-9]{2} [0-9]{3}\b)|((\+351) (9)[0-9]{2} [0-9]{3} [0-9]{3}\b)"></b-input>
            </b-field>
          </b-field>
        </b-step-item>
        <b-step-item 
          step="2" 
          label="Direccion de Facturacion" 
          :clickable="true">
          <b-field label="A la atencion de">
            <b-input v-model="s2_attn"></b-input>
          </b-field>
          <b-field label="Direccion">
            <b-input v-model="s2_address" required></b-input>
          </b-field>
          <b-field grouped>
            <b-field label="Ciudad" expanded>
              <b-input v-model="s2_addressCity" required></b-input>
            </b-field>
            <b-field label="Provincia" expanded>
              <b-input v-model="s2_addressProvince" required></b-input>
            </b-field>
          </b-field>
          <b-field grouped>
            <b-field label="Codigo Postal" expanded>
              <b-input v-model="s2_addressZIP" required></b-input>
            </b-field>
            <b-field label="Pais" expanded>
              <b-input v-model="s2_addressCountry" required></b-input>
            </b-field>
          </b-field>
        </b-step-item>

        <b-step-item 
          step="3" 
          label="Direccion de Envio" 
          :clickable="true">
          <b-field label="Usar direccion de facturacion para los envios">
            <b-checkbox v-model="s3_addresses_equal" v-on:input="change_shipping_address"/>
          </b-field>
          <b-field label="A la atencion de">
            <b-input v-model="s3_attn"></b-input>
          </b-field>
          <b-field label="Direccion">
            <b-input v-model="s3_address" required></b-input>
          </b-field>
          <b-field grouped>
            <b-field label="Ciudad" expanded>
              <b-input v-model="s3_addressCity" required></b-input>
            </b-field>
            <b-field label="Provincia" expanded>
              <b-input v-model="s3_addressProvince" required></b-input>
            </b-field>
          </b-field>
          <b-field grouped>
            <b-field label="Codigo Postal" expanded>
              <b-input v-model="s3_addressZIP" required></b-input>
            </b-field>
            <b-field label="Pais" expanded>
              <b-input v-model="s3_addressCountry" required></b-input>
            </b-field>
          </b-field>
        </b-step-item>

        <b-step-item 
          step="4" 
          label="Detalles de pago" 
          :clickable="true">
          <b-field label="IBAN">
            <b-input v-model="s4_IBAN" required pattern="[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?!(?:[ ]?[0-9]){3})(?:[ ]?[0-9]{1,2})?\b"></b-input>
          </b-field>
          <b-field label="Direccion Banco">
            <b-input v-model="s4_bankAddress" required></b-input>
          </b-field>
          <b-field grouped>
            <b-field label=" C.I.F." expanded>
              <b-input v-model="s4_CIF" required pattern="([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])\b"></b-input>
            </b-field>
            <b-field label="Impuesto">
              <b-select  v-model="s4_tax" required>
                <option v-for="(tax,index) in available_taxes" v-bind:key="index" :value="tax.tax_name">{{tax.tax_name}}</option>
              </b-select>
            </b-field>
            <b-field label="Terminos de Pago">
              <b-select  v-model="s4_terms" required>
                <option v-for="(term,index) in Object.keys(available_terms)" v-bind:key="index" :value="term">{{available_terms[term].term_name}}</option>
              </b-select>
            </b-field>
          </b-field>
        </b-step-item>

        <b-step-item 
          step="5" 
          label="Password" 
          :clickable="true">
          <b-field label="Password">
            <b-input v-model="s5_password" type="password" password-reveal required></b-input>
          </b-field>
          <b-field label="Repeat Password">
            <b-input v-model="s5_password2" type="password" required></b-input>
          </b-field>      
        </b-step-item>
      </b-steps>
    </section>
    <footer class="modal-card-foot">
      <!--button class="button is-primary" @click.stop.prevent="login_to_keaa"-->
      <button class="button is-primary" @click.stop.prevent="postUser">
        Sign Up
      </button>
    </footer>

    <b-loading :is-full-page=true v-model="sending_login" :can-cancel=false>
      <b-icon
        pack="fas"
        icon="sync-alt"
        size="is-large"
        custom-class="fa-spin">
      </b-icon>
    </b-loading>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "NewUserForm",
  components: {},
  data() {
    return {
      available_taxes: [],
      available_terms: {},
      activeStep:0,
      sending_login:false,

      s1_company         : null,
      s1_treatment       : null,
      s1_contactName     : null,
      s1_contactSurname  : null,
      s1_contactEmail    : null,
      s1_contactPhone    : null,
      s1_contactCellular : null,

      s2_attn            : null,
      s2_address         : null,
      s2_addressCity     : null,
      s2_addressProvince : null,
      s2_addressZIP      : null,
      s2_addressCountry  : null,

      s3_addresses_equal : false,
      s3_attn            : null,
      s3_address         : null,
      s3_addressCity     : null,
      s3_addressProvince : null,
      s3_addressZIP      : null,
      s3_addressCountry  : null,

      s4_IBAN            : null,
      s4_bankAddress     : null,
      s4_CIF             : null,
      s4_tax             : null,
      s4_terms           : null,

      s5_password        : null,
      s5_password2       : null

    };
  },

  methods: {
    postUser: function() {
      this.sending_login  = true;
      var billing_address = {
        attention : this.s2_attn,
        address   : this.s2_address,
        city      : this.s2_addressCity,
        state     : this.s2_addressProvince,
        zip       : this.s2_addressZIP,
        country   : this.s2_addressCountry
      };

      var shipping_address = {
        attention : this.s3_attn,
        address   : this.s3_address,
        city      : this.s3_addressCity,
        state     : this.s3_addressProvince,
        zip       : this.s3_addressZIP,
        country   : this.s3_addressCountry
      };

      var contact_person = { 
        salutation : this.s1_treatment,
        first_name : this.s1_contactName,
        last_name  : this.s1_contactSurname,
        email      : this.s1_contactEmail,
        phone      : this.s1_contactPhone,
        mobile     : this.s1_contactCellular
      }
    
      axios({
        method: "POST",
        url   : process.env.VUE_APP_BASE_URI + "/login/login_new_user",
        data  : { 
          user_dict : {
            contact_name        : this.s1_company,
            payment_terms       : this.available_terms[this.s4_terms].term_days,
            payment_terms_label : this.available_terms[this.s4_terms].term_name,
            contact_type        : 'customer',
            custom_fields       : [
              {
                'customfield_id' : '1777375000000126103',
                'value'           :  this.s4_CIF
              },
              {
                'customfield_id' : '1777375000000220059',
                'value'           : this.available_terms[this.s4_terms].term_name
              },
              {
                'customfield_id' : '1777375000000250777',
                'value'           : this.s4_IBAN
              },
              {
                'customfield_id' : '1777375000000342728',
                'value'           : this.s4_bankAddress
              },
              {
                'customfield_id' : '1777375000004737016',
                'value'           : this.s4_tax
              },
              {
                'customfield_id' : '1777375000018282189',
                'value'           :  this.s5_password
              },
            ],
            billing_address     : billing_address,
            shipping_address    : shipping_address,
            contact_persons     : [contact_person]
          }
        }
      }).then(() => { 
        this.$emit('sign_up_successful');
      });
    },
    change_shipping_address: function () {
      if (this.s3_addresses_equal){
        this.s3_attn            = this.s2_attn;
        this.s3_address         = this.s2_address;
        this.s3_addressCity     = this.s2_addressCity;
        this.s3_addressProvince = this.s2_addressProvince;
        this.s3_addressZIP      = this.s2_addressZIP;
        this.s3_addressCountry  = this.s2_addressCountry;
      }else{
        this.s3_attn            = null;
        this.s3_address         = null;
        this.s3_addressCity     = null;
        this.s3_addressProvince = null;
        this.s3_addressZIP      = null;
        this.s3_addressCountry  = null;
      }
    }
  },

  mounted() {
    axios({
        method: "GET",
        url: process.env.VUE_APP_BASE_URI + "/login/taxes",
      }).then((result) => { 
        var response = result.data;
        var taxes    = response.items;

        this.available_taxes = taxes;
      });

    axios({
        method: "GET",
        url: process.env.VUE_APP_BASE_URI + "/login/payment_terms",
      }).then((result) => { 
        var response = result.data;
        var terms    = response.items;

        this.available_terms = terms;
      });
  }
};
</script>
