import Vue   from 'vue'
import Buefy from 'buefy'
//import 'buefy/dist/buefy.css'
import '@/assets/scss/main.scss'
import App      from './App.vue'
import router   from './router'
import store    from './store'

Vue.config.productionTip = false
Vue.use(Buefy);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')



