import Vue from 'vue'
import axios from "axios";

Vue.use(axios)


export const keaaApi = axios.create({
    baseURL: process.env.VUE_APP_BASE_URI ,
    withCredentials: true
});

