<template>
  <div id="app">
    <link
      rel="stylesheet"
      href="https://cdn.materialdesignicons.com/5.3.45/css/materialdesignicons.min.css"
    />
    <link 
      rel="stylesheet" 
      href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
    />

    <LoginForm v-if="show_login" v-on:login_successful="login_form_closed"/>

    <b-loading
      :is-full-page="true"
      v-model="show_loading"
      :active.sync="show_loading"
      :can-cancel=false
    >
      <b-icon
        pack="fas"
        icon="sync-alt"
        size="is-large"
        custom-class="fa-spin"
      ></b-icon>
    </b-loading>

    <b-navbar :fixed-top=true class="is-light">
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img src="./assets/logo definitiu gran - Edited.png" />
        </b-navbar-item>
      </template>
      <template slot="end">
        <b-navbar-item tag="router-link" :to="{ path: '/shop' }"
          >Tienda</b-navbar-item
        >
        <b-navbar-item tag="router-link" :to="{ path: '/orders' }"
          >Pedidos</b-navbar-item
        >
        <b-navbar-item tag="router-link" :to="{ path: '/cart' }"
          >Carrito</b-navbar-item
        >
        <b-navbar-item
          v-if="user_role=='administrator'"
          tag="router-link"
          :to="{ path: '/admin' }"
          >Administracion</b-navbar-item
        >
        <b-navbar-item>
          <b-button v-on:click="logout">
            Logout
          </b-button>
        </b-navbar-item>
      </template>
    </b-navbar>
    <br />
    <router-view v-if="!show_loading && !show_login"/>
  </div>
</template>


<script>
import LoginForm               from "./components/login_form";
import { keaaApi }             from "./plugins/keaa_api";

export default {
  name: "App",
  components: {
    LoginForm
  },
  data: () => ({
    show_loading: true,
    show_login  : false,
  }),
  computed: {
    user_role: function () {
      var a = this.$store.state.user_is_admin;
      return a;
      },
  },
  methods: {
    register_status() {
      this.show_login = !this.show_login;
    },

    forward_to_catalog() {
      if (this.$router.currentRoute.path != "/shop") {
        this.$router.push("/catalog");
      }
    },
    forward_to_purchaseorders() {
      if (this.$router.currentRoute.path != "/orders") {
        this.$router.push("/");
      }
    },
    forward_to_shopping_cart() {
      if (this.$router.currentRoute.path != "/cart") {
        this.$router.push("/");
      }
    },
    login_form_closed: function() {
      this.show_login = false;
    },
    logout: function() {
      window.sessionStorage.clear();
      window.localStorage.clear();
      keaaApi({
        method: "GET",
        url: "login/logout_cookie",
      }).then( window.location.reload(true) )
    }
  },
  created() {
    keaaApi({
      method: "GET",
      url: "login/login_cookie",
    }).then((result) => {
        var response       = result.data;
        var user_id        = response.user_id;
        var seller_id      = response.sales_id;
        var pricebook_id   = response.pricebook_id;
        var admin          = response.keaa_admin;
        this.$store.commit("user_id", user_id);
        this.$store.commit("seller_id", seller_id);
        this.$store.commit("user_is_admin", admin);
        this.$store.commit("pricebook_id", pricebook_id);
        this.show_login    = false;
        this.show_loading  = false;
      })
      .catch(() => {
        this.show_login    = true;
        this.show_loading  = false;
        this.$store.commit("empty_shopping_cart")
      });
  },

  mounted() {
  },
};
</script>


<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
  word-spacing: 1px;
  color: #2c3e50;
  margin-top: 60px;
}

</style>

