
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from "secure-ls";

var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: {

    locale: 'es-ES',
    user_id: undefined,
    seller_id: undefined,
    user_is_admin: '',
    customer: null,

    order_is_book: false,
    booking_date: null,
    product_catalog: [],
    product_filters: [],
    product_filters_active: {},
    price_display: {},
    catalog_page: 1,
    catalog_download_in_process: false,
    option_stock: [],

    shopping_cart: {}
  },
  getters: {
    user_id: state => state.user_id,
    seller_id: state => state.seller_id,
    user_is_admin: state => state.user_is_admin,
    customer: state => state.customer,
    
    order_is_book: state => state.order_is_book,
    booking_date: state => new Date(state.booking_date),
    product_catalog: state => state.product_catalog,
    product_filters: state => state.product_filters,
    product_filters_active: state => state.product_filters_active,
    price_display: state => state.price_display,
    product_amount: state => item_id => {
      if (item_id in state.shopping_cart) {
        return state.shopping_cart[item_id].amount
      }
      else {
        return 0
      }
    },
    catalog_page: state => state.catalog_page,
    catalog_download_in_process: state => state.catalog_download_in_process,
    option_stock: state => state.option_stock
  },
  mutations: {
    user_id(state, user_id) {
      state.user_id = user_id
    },
    seller_id(state, seller_id) {
      state.seller_id = seller_id
    },
    user_is_admin(state, user_is_admin) {
      state.user_is_admin = user_is_admin
    },
    set_customer(state, customer) {
      state.customer = customer
    },

    set_order_type(state,order_is_book) {
      state.order_is_book = order_is_book
    },
    set_booking_date(state, date) {
      state.booking_date = date
    },
    product_catalog(state, catalog) {
      state.product_catalog = catalog
    },
    product_filters(state, product_filters) {
      state.product_filters = product_filters
    },
    product_filter_activate(state, filter) {
      state.product_filters_active[filter['filter_id']] = filter['filter_value']
    },
    set_price_display (state, display) {
      state.price_display = display
    },
    add_item_to_shopping_cart(state, amount_dict) {
      if (amount_dict.amount < 0) {
        return
      }
      else if (amount_dict.amount == 0) {
        delete state.shopping_cart[amount_dict.product.item_id]
      }
      else {
        if (!Object.keys(amount_dict).includes('discount')) {
          amount_dict.discount = null
        }
        state.shopping_cart[amount_dict.product.item_id] = { amount: amount_dict.amount, item: amount_dict.product, discount: amount_dict.discount, price: amount_dict.price }
      }
    },
    add_pack_to_shopping_cart(state, amount_dict) {
      if (amount_dict.amount < 0) {
        return
      }
      else if (amount_dict.amount == 0) {
        delete state.shopping_cart[amount_dict.product.item_id]
      }
      else {
        if (!Object.keys(amount_dict).includes('discount')) {
          amount_dict.discount = null
        }
        state.shopping_cart[amount_dict.product.composite_item_id] = { amount: amount_dict.amount, item: amount_dict.product, discount: amount_dict.discount, price: amount_dict.price}
      }
    },
    empty_shopping_cart(state) {
      state.shopping_cart = {}
    },
    purge_shopping_cart(state) {
      for (var key in state.shopping_cart) {
        if (state.shopping_cart[key].amount == 0) {
          delete state.shopping_cart[key];
        }
      }
    },
    set_catalog_page(state, page) {
      state.catalog_page = page;
    },
    set_catalog_download_in_process (state, is_in_process) {
      state.catalog_download_in_process = is_in_process;
    },
    set_option_stock (state, option_stock) {
      state.option_stock = option_stock
    }
  }
})
