<template>
    <div class="card" v-if="display">
      <div class="card-image">
        <figure class="image center">
          <div class="image_container">
            <img
              :src="get_product_image_url(product_info.items[product_key], 200)"
              style="height: 60"
            />
            <div class="image_corner">
              <b-tag v-if="group_amount_to_order" rounded type="is-danger" size="is-medium">{{group_amount_to_order}}</b-tag>
            </div>
            <div class="image_corner_2">
              <strong>PACK</strong>
            </div>
          </div>
        </figure>
      </div>
      <div class="card-content is-small">
        <div class="columns is-mobile" style="margin-bottom:10px">
          <div class="column" style="padding:3px">
            <strong>{{ product_brand }}</strong>
          </div>
          <div class="column" style="padding:3px;text-align:right !important">
            <strong>{{ product_season }}</strong>
          </div>
        </div>
        <div class="columns is-mobile" style="margin-bottom:3px">
          <div class="column" style="padding:3px;text-align:center; !important">
            <strong>{{ product_name }}</strong>
            <br>
            {{ product_sku }}
          </div>
        </div>
        <div v-if="display_prices.current_discount" class="columns is-mobile" style="margin-bottom:3px">
          <div class="column" style="padding:3px">
           DESCUENTO:
          </div>
          <div class="column" style="padding:3px;text-align:right !important">
            {{ product_discount }}
          </div>
        </div>
        <div v-if="display_prices.custom_rate" class="columns is-mobile" style="margin-bottom:3px">
          <div class="column" style="padding:3px">
            TARIFA:
          </div>
          <div class="column" style="padding:3px;text-align:right !important">
            {{ product_price_rounded }}
          </div>
        </div>
        <div v-if="display_prices.custom_rpvp" class="columns is-mobile" style="margin-bottom:3px">
          <div class="column" style="padding:3px">
            RPVP:
          </div>
          <div class="column" style="padding:3px;text-align:right !important">
            {{ product_rpvp }}
          </div>
        </div>
      </div>
      <footer class="card-footer" height="100%">
        <div class="card-footer-item">
          <div class="columns is-centered">
            <div class="column is-11">
              <b-field grouped width="100%">
                <b-radio-button size="is-small" v-on:click.native="add_to_cart">
                  <b-icon icon="cart-arrow-down"></b-icon>
                </b-radio-button>
                <b-input
                  size="is-small"
                  placeholder="Number"
                  type="number"
                  min=0
                  expanded
                  :disabled=amount_disabled
                  v-model="item_amount_to_order"
                  v-on:keyup.native.enter="add_to_cart"
                >
                </b-input>
              </b-field>

              <div v-if="'options' in product_info">
                <entryOption
                  v-for="(key, index) in Object.keys(product_info.options)"
                  :key="index"
                  :option_info="product_info.options[key]"
                  :option_index="index"
                  :option_name="key"
                  :first_selection="product_key_components[index]"
                  v-on:optionSelection="selectOption"
                  v-on:optionFocus="enableOption"
                />
              </div>
              <div v-if="order_is_book">
                <small>Disponible desde: <strong>{{ product_info.price[product_key]["booking_since"] }}</strong></small>
              </div>
              <div v-else>
                <div v-for="(stock,index) in item_stock" :key="index" rounded>
                  <small>{{ stock[0] }}: {{ stock[1] }}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import entryOption from "@/components/catalog-entry-option";

export default {
  name: "catalogEntryPack",
  components: { entryOption },
  data: () => ({
    product_key           : null,
    product_key_components: [],
    display               : false,
    item_amount_to_order  : null,
    group_amount_to_order : null,
    amount_disabled       : false
  }),
  props: {
    product_info: Object,
    display_prices: Object
  },

  computed: {
    ...mapGetters([ "product_amount", "order_is_book"]),

    product_name: function () {
      if (
        "cf_nombre_comercial_unformatted" in
        this.product_info.items[this.product_key]
      ) {
        return this.product_info.items[this.product_key][
          "cf_nombre_comercial_unformatted"
        ];
      } else {
        return this.product_info.items[this.product_key]["name"];
      }
    },
    product_brand: function () {
      return this.product_info.items[this.product_key]["brand"];
    },
    product_season: function () {
      if (this.order_is_book) {
        return this.product_info.price[this.product_key]["booking_season"]
      }else{
        return this.product_info.items[this.product_key]["cf_temporada_unformatted"];
      }
    },
    product_discount: function () {
      if (this.order_is_book) {
        return ((this.product_price - this.product_info.price[this.product_key]["discounted_booking_rate"]) / this.product_price * 100).toFixed(0)
      }else{
        return ((this.product_price - this.product_info.price[this.product_key]["discounted_rate"]) / this.product_price * 100).toFixed(0)
      }
    },
    product_price: function () {
      var custom_price = null
      if (this.order_is_book) {
        custom_price = this.product_info.price[this.product_key]["booking_rate"]
      }else{
        custom_price = this.product_info.price[this.product_key]["custom_rate"]
      }
      if (!custom_price) {
        custom_price = this.product_info.items[this.product_key]["rate"]
      }
      return custom_price
    },
    product_price_rounded: function () {
      var custom_price = null
      if (this.order_is_book) {
        custom_price = this.product_info.price[this.product_key]["booking_rate"]
      }else{
        custom_price = this.product_info.price[this.product_key]["custom_rate"]
      }
      if (!custom_price) {
        custom_price = this.product_info.items[this.product_key]["rate"]
      }
      return parseFloat(custom_price).toFixed(2)
    },
    product_rpvp: function () {
      var custom_rpvp = null
      if (this.order_is_book) {
        custom_rpvp = this.product_info.price[this.product_key]["booking_rpvp"]
      }else{
        custom_rpvp = this.product_info.price[this.product_key]["custom_rpvp"]
      }
      if (!custom_rpvp) {
        custom_rpvp = this.product_info.items[this.product_key]["rpvp"]
      }
      return parseFloat(custom_rpvp).toFixed(2)
      // return custom_rpvp
    },
    item_stock: function () {
      return this.product_info.stock[this.product_key];
    },
    product_sku: function () {
      return this.product_info.items[this.product_key]["sku"];
    }
  },
  mounted() {
    var key          = Object.keys(this.product_info.items)[0];
    this.product_key = key;

    var key_components      = new Array();
    var component_key_root  = "attribute_option_name";
    var component_key_index = 1;

    var loop = true;
    while(loop == true)
    {
      var component_key = component_key_root + component_key_index;
      if ( !(component_key in this.product_info.items[key] )){
        loop = false;
        continue
      }
      if ( this.product_info.items[key][component_key] == "" ){
        loop = false;
        continue
      }
      key_components.push(this.product_info.items[key][component_key]);
      component_key_index +=1;
    }

    if (key_components.length == 0) {
      this.product_key_components.push(this.product_key);
    }
    else{
      this.product_key_components = key_components;
    }

    this.display = true;

    this.item_amount_to_order = this.get_product_amount(
      this.product_info.items[key].composite_item_id
    );

    this.group_amount_to_order = this.get_group_amount();
  },

  methods: {
    enableOption: function  (index) {
      const option_stock = []
      const options = Object.keys(this.product_info.options)
      let   option = options[index]
      
      for (var value of this.product_info.options[option] ) {
        var key_value    = [...this.product_key_components];
        key_value[index] = value
        key_value        = key_value.join("");
        var stock        = 0
        for ( var stock_tuple of this.product_info.stock[key_value]) {
          stock += stock_tuple[1]
        }
        option_stock.push(stock)
      }
      this.$store.commit("set_option_stock", option_stock)
    },
    selectOption: function (index, option) {
      this.product_key_components[index] = option;
      var key_value    = [...this.product_key_components];
      key_value        = key_value.join("");
      
      if(key_value in this.product_info.items) {
        this.product_key = key_value;
        this.item_amount_to_order = this.get_product_amount(
          this.product_info.items[key_value].composite_item_id
        );
        this.amount_disabled =false
      }
      else{
        this.item_amount_to_order = 0;
        this.amount_disabled      = true;
      }
    },
    get_product_image_url: function (item, size) {
      var url;
      if (item.image_name != "") {
        url = process.env.VUE_APP_BASE_URI + "/b2b/images/by_file/" + item.image_name + '?width=' + size + '&height=' + size + '&item_id=' + item.composite_item_id;
        return url;
      } else {
        url = process.env.VUE_APP_BASE_URI + "/b2b/images/by_item_id/" + item.composite_item_id + '?width=' + size + '&height=' + size;
        return url;
      }
    },
    add_to_cart: function () {
      var product = this.product_info.items[this.product_key];
      var price   = this.product_price_rounded;
      // var price   = this.product_info.price[this.product_key];
      this.$store.commit("add_pack_to_shopping_cart", {
        product: product,
        price  : price,
        amount : parseInt(this.item_amount_to_order),
      });

      this.group_amount_to_order = this.get_group_amount(product.composite_item_id)
    },

    get_product_amount: function (item_id) {
      var amount = this.product_amount(item_id);
      return amount;
    },

    get_group_amount: function () {
      var group_amount = 0;
      for (var item_key in this.product_info.items) {
        var item_id = this.product_info.items[item_key].composite_item_id;
        group_amount += this.get_product_amount(item_id);
      }
      return group_amount;
    }
  },
};
</script>

<style scoped>
.card {
  height: 100%;
}

.image_container {
  position: relative;
  text-align: center;
  color: white;
}

/* Bottom left text */
.image_corner {
  position: absolute;
  top: 8px;
  right: 16px;
}

.image_corner_2 {
  position: absolute;
  top: 8px;
  left: 16px;
}
</style>