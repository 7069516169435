<template>
  <div>
    <section class="modal-card-body">
      <div class="card">
        <div class="card-image">
          <figure class="image is-4by2" v-on:click="redirect_to_keaa">
            <img src="@/assets/logo_medio.png" />
          </figure>
        </div>
        <div class="card-content">
          <div class="content">
            Por favor revisa tu bandeja de entrada y confirma tu identidad
            entrando en el link enviado por KEAA. Revisa tu carpeta de SPAM si
            no encuentras tu nuestro email.
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      
    </footer>
  </div>
</template>

<script>
export default {
  name: "SuccessfulSignUp",
  methods: {
    redirect_to_keaa: function() {
      window.location.href = 'https://www.keaa.es';
    }
  }
};
</script>