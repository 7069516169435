<template>
  <div class="column is-one-eigth">
    <b-select 
      size="is-small" 
      :placeholder="filteredPropName" 
      :expanded="true" 
      v-model="filter_info['selected']"
      v-on:input="emit_filter_change">
      <option :value="null">-</option>
      <option v-for="(val, index) in arrayToFilter" :key="index">{{val}}</option>
    </b-select>
  </div>
</template>

<script>
export default {
  name: "catalogFilter",
  data: () => ({
    value: null,
    arrayToFilter: [],
    filteredPropName: ""
  }),
  props: {
    filter_info: Object
  },
  methods: {
    emit_filter_change: function () {
      this.$emit("mySearch");
    },
  },
  mounted() {
    var values = this.filter_info.values;
    for (var index in values) {
      if (values[index] != "") {
        this.arrayToFilter.push(values[index]);
      }
    }
    this.filteredPropName = this.filter_info.name;
  }
};
</script>