<template>
  <b-modal :active="active" :can-cancel="false" trap-focus>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Login</p>
        <button class="button is-primary-light" v-on:click="change_form">New User</button>
      </header>
      <SuccessfulSignUp v-if="sign_up_done"/>
      <ExistingUserForm v-else-if="existing_user" v-on:login_successful="login_successful"/>
      <NewUserForm      v-else                    v-on:sign_up_successful="sign_up_successful"/>
    </div>
  </b-modal>  
</template>

<script>
import SuccessfulSignUp  from "@/components/message_sign_up";
import ExistingUserForm  from "@/components/login_existing_user_form";
import NewUserForm       from "@/components/login_new_user_form";

export default {
  name: "LoginForm",
  components: { SuccessfulSignUp, ExistingUserForm, NewUserForm },
  data() {
    return {
      sign_up_done  : false,
      existing_user : true,
      active        : true
    };
  },
  methods:{
    login_successful:function() {
      this.active=false;
      this.$emit('login_successful');
    },
    sign_up_successful: function() {
      this.sign_up_done = true
    },
    change_form:function() {
      this.existing_user = !this.existing_user;
    }
  }
};
</script>
