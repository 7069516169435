<template>
    <b-select :placeholder="option_name" size="is-small" expanded v-on:input="onChange" v-on:mousedown.native="onFocus" v-model="value">
      <option v-for="(option,option_id) in option_info" :key="option_id" :value="option">
        <div v-if="option === value">
          {{option}}
        </div>
        <div v-else>
          {{stock_for_option_value(option_id)}}x - {{option}}
        </div>
      </option>
    </b-select>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "entryOption",
  data: () => ({
    value: null,
  }),
  props: {
    option_info    : Array,
    option_index   : Number,
    option_name    : String,
    first_selection: String
  },
  computed: {
    ...mapGetters(["option_stock"])
  },
  methods: {
    onChange: function () {
      this.$emit("optionSelection", this.option_index, this.value);
    },
    onFocus: function () {
      this.$emit("optionFocus", this.option_index);
    },
    stock_for_option_value: function(index) {
      return this.option_stock[index]
    }
  },
  mounted() {
    this.value = this.first_selection;
  },
};
</script>

